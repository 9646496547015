/* eslint-disable react/no-array-index-key */
import React from "react"
import BackgroundImage from "../components/background-image"
import { SectionType } from "../../../types"
import { createMarkup } from "../../../../utils/markup-creator"

type SectionProps = {
  section: SectionType
  index: any
  [key: string]: any
}

const FaqSection = ({ section, index, ...props }: SectionProps) => {
  
  return (
    <div className={`relative ${section.advanced?.class || ``}`}>
      <BackgroundImage
        url={section.advanced?.backgroundImageLg}
        mobileUrl={section.advanced?.backgroundImageSm}
        height={section.advanced?.backgroundHeight}
        backgroundPosition={section.advanced?.backgroundPosition}
        backgroundColor={section.advanced?.backgroundColor}
        backgroundFit={section.advanced?.backgroundFit}
      />
      <div className="max-w-screen-lg mx-auto px-4 sm:px-6 lg:px-0">
        {section.blurb && <div dangerouslySetInnerHTML={createMarkup(section.blurb)} />}
        <ul>
          {section.qa &&
            section.qa.map((qa, faqIndex) => (
              <li key={`qa-${faqIndex}`} className="py-6">
                <h4 className="pb-2 text-primary">{qa.q}</h4>
                <div dangerouslySetInnerHTML={createMarkup(qa.a)} />
              </li>
            ))}
        </ul>
      </div>
    </div>
  )
}

export default FaqSection
